//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getDictionary,
         getLang } from '~/utils/butterUtils'

export default {
  head () {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription
        }
      ],
      link: [ 
        {
          rel: 'canonical',
          href: process.env.url+this.$route.path
        }
      ]
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/faqs/index.vue asyncData ')
  },
  data () {
    return {
      openedDetails: [],
      isLoading: true,
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    pageMetaDescription () {
      return this.dict.SEO_A16
    },
    pageTitle () {
      return process.env.siteTitle + " | " +  this.dict.SEO_A15
    },
    sortedCategories () {
      const scr1 = this.butterFaqCategories
      const scr2 = {}
      for (let ii = 0; ii < scr1.length; ii++) {
        const v1 = scr1[ii].category
        const v2 = {}
        v2.category_order = scr1[ii].category_order
        v2.header_enus = scr1[ii].header_enus
        v2.header_eses = scr1[ii].header_eses
        v2.header_kokr = scr1[ii].header_kokr
        scr2[v1] = v2
      }
      return scr2
    },
    categories () {
      const scr0 = this.butterFaqQuestions
      const ref = []

      if (!scr0) {
        return ref
      }

      const scratch = {}
      
      for (let ii = 0; ii < scr0.length; ii++) {
        const scr1 = scr0[ii]
        const cat = scr1.category
        if (!cat) continue

        if (!Object.prototype.hasOwnProperty.call(scratch, cat)) {
          const categoryHeader = this.sortedCategories[cat]['header_' + this.lang]
          let categoryOrder = String(this.sortedCategories[cat].category_order)
          if (categoryOrder.length == 1) {categoryOrder = "0"+categoryOrder}
          scratch[cat] = {'category': cat, 'category_order': categoryOrder, 'categoryheader': categoryHeader, 'faqs': []}
        }

        const oneFAQ = {}
        oneFAQ[cat] = scr1[cat]
        let questionorder = String(scr1.questionorder)
        if (questionorder.length == 1) {questionorder = "0"+questionorder}
        if (questionorder.length == 2) {questionorder = "0"+questionorder}
        oneFAQ.questionorder = questionorder
        oneFAQ.slug = scr1.slug
        oneFAQ.question = scr1['question_' + this.lang]
        scratch[cat].faqs.push(oneFAQ)
      }

      for (const cat in scratch) {
        scratch[cat].faqs.sort((a, b) => a.questionorder.localeCompare(b.questionorder))
        ref.push(scratch[cat])
      }

      return ref.sort((a, b) => a.category_order.localeCompare(b.category_order))
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterFaqCategories () {
      return this.$store.state.butterFaqCategories
    },
    butterFaqQuestions () {
      return this.$store.state.butterFaqQuestions
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
  },
  mounted () {
    this.isLoading = false
  },
}
